import React, { Component } from "react"
import { Helmet } from 'react-helmet';
import Col from 'react-bootstrap/Col';
import OrderComponent from '../components/OrderList.js';


class Order extends Component {

    componentDidMount() {
        document.getElementById('main').classList.add('page-order')
    }

    componentWillUnmount() {
        document.getElementById('main').classList.remove('page-order')
    }

    render() {
        return (
            <>
                <Helmet>
                  <title>Mes commandes | Restofuté</title>
                  <meta name="description" content="Liste de vos commandes" />
                </Helmet>
                
                <Col id="orders" className="order-page" xs="12" sm="12" lg="10">
                    <h1>Historique de vos commandes</h1>
                    <p>Retrouvez l’intégralité de vos commandes Restofuté</p>
                    <OrderComponent {...this.props} />
                </Col>
                    
            </>
        );
    }
}

export default Order;