let configData = {};
if (process.env.REACT_APP_ENV === 'prod') {
	configData = {
	  "API_ENDPOINT": "https://api.restofute.com/graphql/",
	  "API_URL": "https://api.restofute.com",
	  "DISCONNECT": "Déconnectez vous",
	  "CONTACT_URL": "/profile",
	  "CONTACT_PHONE": "tel:0561442859",
	  "CONTACT_MAIL": "mailto:contact@restofute.com",
	  "DOWNLOAD_APP": "Télécharger l'application",
	  "DOWNLOAD_APP_MAC": "http://restofute.com",
	  "DOWNLOAD_APP_GOOGLE": "http://restofute.com",
	  "FB": "https://www.facebook.com/restofute.fr/",
	  "TWITTER": "https://twitter.com/restofute",
	  "INSTAGRAM": "https://www.instagram.com/restofute/?hl=fr",
	  "LINKEDIN": "https://www.linkedin.com/company/11482949/admin/",
	  "NEWS_INFOS": "Ne manquez pas toutes nos actualités",
	  "NEWS_BUTTON" : "Découvrez tous les articles",
	  "NEWS_URL" : "https://www.restofute.fr/blog/"
	}
}else if (process.env.REACT_APP_ENV === 'preprod') {
	configData = {
	  "API_ENDPOINT": "https://preprod-api.restofute.com/graphql/",
	  "API_URL": "https://preprod-api.restofute.com",
	  "DISCONNECT": "Déconnectez vous",
	  "CONTACT_URL": "/profile",
	  "CONTACT_PHONE": "tel:0561442859",
	  "CONTACT_MAIL": "mailto:contact@restofute.com",
	  "DOWNLOAD_APP": "Télécharger l'application",
	  "DOWNLOAD_APP_MAC": "http://restofute.com",
	  "DOWNLOAD_APP_GOOGLE": "http://restofute.com",
	  "FB": "https://www.facebook.com/restofute.fr/",
	  "TWITTER": "https://twitter.com/restofute",
	  "INSTAGRAM": "https://www.instagram.com/restofute/?hl=fr",
	  "LINKEDIN": "https://www.linkedin.com/company/11482949/admin/",
	  "NEWS_INFOS": "Ne manquez pas toutes nos actualités",
	  "NEWS_BUTTON" : "Découvrez tous les articles",
	  "NEWS_URL" : "https://www.restofute.fr/blog/"
	}
}else{
	configData = {
	  "API_ENDPOINT": "https://restofute-api.wsextranet.net/graphql/",
	  "API_URL": "https://restofute-api.wsextranet.net",
	  "DISCONNECT": "Déconnectez vous",
	  "CONTACT_URL": "/profile",
	  "CONTACT_PHONE": "tel:0561442859",
	  "CONTACT_MAIL": "mailto:contact@restofute.com",
	  "DOWNLOAD_APP": "Télécharger l'application",
	  "DOWNLOAD_APP_MAC": "http://restofute.com",
	  "DOWNLOAD_APP_GOOGLE": "http://restofute.com",
	  "FB": "https://www.facebook.com/restofute.fr/",
	  "TWITTER": "https://twitter.com/restofute",
	  "INSTAGRAM": "https://www.instagram.com/restofute/?hl=fr",
	  "LINKEDIN": "https://www.linkedin.com/company/11482949/admin/",
	  "NEWS_INFOS": "Ne manquez pas toutes nos actualités",
	  "NEWS_BUTTON" : "Découvrez tous les articles",
	  "NEWS_URL" : "https://www.restofute.fr/blog/"
	}
}
export default configData;