

export function prettyFloat(value,decimals){
	if(!decimals){
		decimals = 2;
	}
	let originalValue = value;
	if(isNaN(value)){
		return value;
	}
	value = parseFloat(value);
	value = value.toFixed(decimals);
	value = value.replace('.', ',');
	return value;
}