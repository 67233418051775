import React, { Component } from "react"
import { Helmet } from 'react-helmet';
import Col from 'react-bootstrap/Col';
import Order from '../components/OrderList.js';
import News from '../components/NewsList.js';
import configData from "../Config";

class Dash extends Component {
    
    componentDidMount() {
        document.getElementById('main').classList.add('page-dash')
    }

    componentWillUnmount() {
        document.getElementById('main').classList.remove('page-dash')
    }
    
    render() {

        return (
            <>
                <Helmet>
                  <title>Dashboad | Restofuté</title>
                  <meta name="description" content="Tableau de bord de votre espace" />
                </Helmet>
                
                <Col id="dash" xs="12" sm="12" lg="5">

                    <h1>Bonjour {this.props.user.firstName} {this.props.user.lastName} !</h1>
                    <p>Du nouveau, chez Restofuté</p>
                    <div className="download-app">
                        <div className="col-md-5 lightflare">
                            <img alt="app" src="./img/download.png" className="" />
                        </div>
                        <div className="col-md-7 d-flex align-items-center p-3">
                            <div>
                                <b>Votre interface de commande évolue</b>
                                <b>Si vous avez besoin d’aide contactez-nous au 0661330533</b>
                            </div>
                        </div>
                    </div>

                    <News />

                    <p>Nous suivre</p>
                    <div className="follow">
                        <a href={configData.FB} rel="noreferrer" target="_blank"><img src="./img/facebook.png" alt="appstore" /></a>
                        <a href={configData.TWITTER} rel="noreferrer" target="_blank"><img src="./img/twitter.png" alt="appstore" /></a>
                        <a href={configData.INSTAGRAM} rel="noreferrer" target="_blank"><img src="./img/instagram.png" alt="appstore" /></a>
                        <a href={configData.LINKEDIN} rel="noreferrer" target="_blank"><img src="./img/linkedin.png" alt="appstore" /></a>
                    </div>

                </Col>

                <Col id="orders" className="orderDash" xs="12" sm="12" lg="5">
                    <p>Vos dernières commandes</p>
                    <p><i>Retrouvez l’intégralité de vos commandes Restofuté</i></p>
                    <Order {...this.props} />
                </Col>
                    
            </>
        );
    }
}

export default Dash;