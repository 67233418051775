import React, { useState, useEffect } from "react";
import DateRangeComponent from './theme/DateRangeComponent.js'
import { format, parseISO } from 'date-fns';
import GraphQLQuery from './database/Query.js';
import configData from "../Config";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { prettyFloat } from '../Helper.js';

const InvoicesList = (props) => {
    
    const [state, setState] = useState({
        query:'',
        loading: false,
        fromDate:'',
        toDate:'',
        list:[],
        originalList:[],
    })

    const [showDetail, setShowDetail] = useState(false);

    const handleShow = () =>{
        console.log('request data detail invoices')
        setTimeout(() => {
            setShowDetail(true)
        },1000)
    }
    
    const handleClose = () => setShowDetail(false)

    // Initial route load
    useEffect(() => {

        console.log('%c InvoiceList useEffect (props changed)', 'color: #1873a4', props)

        setState(s => ({
            ...s,
            loading: true,
        }))

        if(props.user.hasOwnProperty('restaurantAssociations')){
        
            document.body.style.cursor = 'wait'

            let restaurantId = props.user.restaurantAssociations[0].restaurant.id
            
            console.warn('Api load invoice list: loading', restaurantId)

            let datas = []
            let query = new GraphQLQuery({ 
                'queryType': 'billingInvoices',
                'parameters': {
                    'first': 100000,
                    'filter' : {'restaurant':restaurantId},
                    'orderBy' : 'date',
                    'dir' : 'desc',
                },
                'fields': [
                    'id',
                    'reference',
                    'date',
                    'ht',
                    'ttc',
                    'pdf { id, url }'
                ],
                'hasEdges': true,
                'addPageInfo': false,
                'addTotalCount': true,
                'before': function(){
                },
                'success': function(data){ 

                    console.warn('Api load invoice list: ok')
                    let invoices = data.edges
                    for (var i = 0; i < invoices.length; i++){
                        let invoice = invoices[i].node
                        let item = {
                            id:invoice.reference, 
                            date:invoice.date.replace(' ', 'T'),
                            ref:invoice.reference.toString(),
                            ht:invoice.ht,
                            ttc:invoice.ttc,
                            printUrl: configData.API_URL + invoice.pdf.url,
                        }
                        datas.push(item)
                    }

                    setState(s => ({
                        ...s,
                        loading: false,
                        list:datas,
                        originalList:datas,
                    }))

                    document.body.style.cursor = 'auto'

                },
                'error': function(message, data){
                    console.error(message,data)
                },
                'after': function(){
                }
            });

            query.execute();

        }

    }, [props.user])

    let onDateChange = (e) => {

        let fromDate = e.selection.startDate;
        let toDate = e.selection.endDate;
        const results = state.originalList.filter(data => {
            let date = new Date(data.date)
            return date >= fromDate && date <= toDate
        })
        setState({
            ...state,
            fromDate: fromDate,
            toDate: toDate,
            list: results
        })

    }

    let listItems = [];
    if(state.list){
        listItems = state.list.map(function(d, index){
            let ht = prettyFloat(d.ht);
            let ttc = prettyFloat(d.ttc);
            return <tr key={index}>
                <td><span className="small d-lg-none">Référence : </span>{d.ref}</td>
                <td><span className="small d-lg-none">Date : </span>{`${format(parseISO(d.date), "dd/MM/yyyy")}`}</td>
                <td><span className="small d-lg-none">Montant HT : </span>{ht} €</td>
                <td><span className="small d-lg-none">Montant TTC : </span>{ttc} €</td>
                <td><span className="small d-lg-none">Fichier : </span><a href="#" onClick={ (e) => {
                    e.preventDefault()
                    window.open(d.printUrl, "_blank")
                } }><img src="./img/print.svg" width={30} /></a></td>
            </tr>
        })
    }

    return (
        <div>
            <div className="form invoiceForm form-filters">
                <DateRangeComponent onDateChange={onDateChange} />
            </div>
            <Table striped>
              <thead>
                <tr className="d-none d-lg-table-row">
                  <th>Référence facture</th>
                  <th>Date facture</th>
                  <th>Montant HT</th>
                  <th>Montant TTC</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
              {state.loading ? <tr><td>Chargement...</td></tr> : null}
              {listItems}
              </tbody>
            </Table>

            <Modal show={showDetail} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Your invoice</Modal.Title>
                </Modal.Header>
                <Modal.Body>Detail of the invoice</Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Fermer
                  </Button>
  
                </Modal.Footer>
            </Modal>

        </div>
    )
};
export default InvoicesList;