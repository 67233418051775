import React, { Component } from "react"

import MenuJson from './MenuJson.js';

class Nav extends Component {
    
    render() {
        return (
            <MenuJson />
        )
    }
}

export default Nav;