import React, { Component } from "react"
import { Helmet } from 'react-helmet';
import Col from 'react-bootstrap/Col';
import ProductComponent from '../components/ProductList.js';


class Product extends Component {
    
    componentDidMount() {
        document.getElementById('main').classList.add('page-product')
    }

    componentWillUnmount() {
        document.getElementById('main').classList.remove('page-product')
    }
    render() {
        
        return (
            <>
                <Helmet>
                  <title>Mes produits | Restofuté</title>
                  <meta name="description" content="Liste des produits" />
                </Helmet>
                <Col id="products" xs="12" sm="12" lg="10">
                    <h1>Commande</h1>
                    <p>Rechercher un produit</p>
                    <ProductComponent {...this.props} />
                </Col>
                    
            </>
        );
    }
}

export default Product;