import Request from './Request.js';

class Query extends Request {

    defaultOptions(){

        var options = {

            //Query parameters
            'queryType': null,
            'parameters': null,
            'fields': null,
            'hasEdges': false,
            'addPageInfo': false,
            'addTotalCount': false,
        };
        return Object.assign(options,super.defaultOptions());
    }

    initialize() {

        return this;
    }


    buildData(){
        let data = this.settings.data;
        data.append('query',this.buildQuery());
        return data;
    }

    buildQuery(wihtoutEncapsulation){ 

        let queryType = this.settings.queryType;
        let fields = this.settings.fields;
        let parameters = this.settings.parameters;
        let parameterString = '';
        if(parameters && Object.keys(parameters).length > 0){
            
            let parametersStrings =  [];
            let parameterNames = Object.keys(parameters);
            for(var i in parameterNames){
                let name = parameterNames[i];
                let value = parameters[name];
                if(!value){
                    continue;
                }
                if(typeof value === "object" && Object.keys(value).length <= 0){
                    continue;
                }
                let formated = super.stringify(value);

                parametersStrings.push(name+':'+formated);

            }
            parameterString = "("+parametersStrings.join(', ')+")";
        }



        let $queryDataPart = '';

        if(fields && fields.length > 0){
            let fieldsString = fields.join(', ');
            if(this.settings.hasEdges){
                $queryDataPart += `
                    edges{
                      node{
                        ${fieldsString}
                      },
                      cursor
                    }
                `;
            }else{
                $queryDataPart += `
                    ${fieldsString}
                `;
            }
        }
        if(this.settings.addPageInfo){
            $queryDataPart += `
                pageInfo{
                  hasNextPage,
                  hasPreviousPage,
                  startCursor,      
                  endCursor
                }
            `;

        }
        if(this.settings.addTotalCount){
            $queryDataPart += `
                totalCount
            `;
        }

        let query = `
            ${queryType} ${parameterString}{
                ${$queryDataPart}
            }
        `;

        if(wihtoutEncapsulation){
            return query;
        }
        
        query = `
        {
            ${query}
        }
        `;
        return query;
    }

    success(xhr){

        //console.log(xhr)

        let data = xhr.data && xhr.data[this.settings.queryType] ? xhr.data[this.settings.queryType] : null;
        if(!data){
            this.error("Query error",xhr);
        }
        if( this.settings.success && data){
            return this.settings.success(data);
        }
        
    }

    error(message, xhr){

        console.warn(message)
        return super.error(message);

        //let data = xhr[this.settings.queryType];
        //return super.error(message,data);

    }

}

export default Query;

