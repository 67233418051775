import Request from './Request.js';


class Mutation extends Request {

    defaultOptions(){
        var options = {
            //Ajax parameters
            'enctype': 'multipart/form-data',
            'cache': false,
            'contentType': false,
            'processData': false,

            //Mutation parameters
            'mutationType': null,
            'fields': null,
            'variables': null,//format: {NAME:{'type':TYPE, 'value': VALUE}}
            'hasEdges': false,
            'addPageInfo': false,
            'addTotalCount': false
        };
        return Object.assign(super.defaultOptions(),options);
    }

    initialize() {
        
        return this;
    }

    buildData(){
        let data = this.settings.data;
        data.append('query',this.buildQuery());
        data.append('variables',this.buildVariables());
        return data;
    }

    getFields(){
        return this.settings.fields;
    }

    buildQuery(){

        let mutationType = this.settings.mutationType;
        let fields = this.getFields();

        let $queryDataPart = '';

        if(fields && fields.length > 0){
            let fieldsString = fields.join(', ');
            if(this.settings.hasEdges){
                $queryDataPart += `
                    edges{
                      node{
                        ${fieldsString}
                      },
                      cursor
                    }
                `;
            }else{
                $queryDataPart += `
                    ${fieldsString}
                `;
            }
        }
        if(this.settings.addPageInfo){
            $queryDataPart += `
                pageInfo{
                  hasNextPage,
                  hasPreviousPage,
                  startCursor,      
                  endCursor
                }
            `;

        }
        if(this.settings.addTotalCount){
            $queryDataPart += `
                totalCount
            `;
        }

        let variableDefinitions = [];
        let variableParameters = [];

        let variables = this.getVariables();
        let variableNames = Object.keys(variables);

        for(var i in variableNames){
            let name = variableNames[i];
            let variable = variables[name];
            let type = variable.type;
            let nameAsVariable = '$'+name;

            variableDefinitions.push(nameAsVariable+':'+type);
            variableParameters.push(name+':'+nameAsVariable);
        }



        let variableDefinitionString = variableDefinitions.join(', ');
        let variableParameterString = variableParameters.join(', ');

        let query = `
        mutation ${mutationType}(${variableDefinitionString}){
            ${mutationType}(${variableParameterString}){
                ${$queryDataPart}
            }
        }
        `;
        return query;

    }

    getVariables(){
        return this.settings.variables;
    }

    buildVariables(){

        let variableList = {};
        let variables = this.getVariables();
        let variableNames = Object.keys(variables);

        for(var i in variableNames){
            let name = variableNames[i];
            let variable = variables[name];
            let type = variable.type;
            let value = variable.value;
            if(type === 'Int' || type === 'Int!'){
                value = parseInt(value);
            }

            variableList[name] = value;
        }

        return JSON.stringify(variableList); // use self.stringify() instead (error on login...)
    }

    success(xhr){

        if(xhr.errors && xhr.errors.length > 0){
            if(xhr.errors.length > 0){
                for(var i in xhr.errors){
                    let error = xhr.errors[i].message + ' : ' + xhr.errors[i].debugMessage;
                    console.error(error,xhr.errors[i]);
                }
            }
            return this.error("Mutation error",xhr);
        }
        let data = xhr.data[this.settings.mutationType];
        
        if(data.errors && data.errors.length > 0){
            return this.error(data.message,xhr);
        }
        if(!data.success){
            return this.error(data.message,xhr);
        }
        if( this.settings.success && data){
            return this.settings.success(data);
        }
        return this;
    }

    error(message, xhr){
        return super.error(message,xhr);
    }

}

export default Mutation;
