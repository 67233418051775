import React, { Component } from "react"
import { Helmet } from 'react-helmet';
import Col from 'react-bootstrap/Col';
import GraphQLMutation from '../components/database/Mutation.js';
import '../styles/Auth.scss';

class Auth extends Component { 

  constructor(props) {
    super(props);
    this.state = { logged_in : false, message : '', access_token : '', resetPassword: false}
    this.handleSubmit = this.handleSubmit.bind(this);
    this.changeView = this.changeView.bind(this);

    // Renew token if nevessary
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    })
    if(params.token != null){
      localStorage.setItem('access_token', params.token)
      setTimeout(() => {
        window.location.replace("/dash");
      },600)
    }

  }

  componentDidMount() {
    document.getElementById('main').classList.add('page-auth')
  }

  componentWillUnmount() {
    document.getElementById('main').classList.remove('page-auth')
  }

  changeView(e){
    e.preventDefault()

    this.setState({
      ...this.state,
      resetPassword: !this.state.resetPassword
    })
  }

  handleSubmit(event) {

    event.preventDefault();

    localStorage.removeItem('access_token')

    let form = new FormData(event.target)
    let loginValue = form.get('email')
    let passwordValue = form.get('password')
    let self = this

    if(this.state.resetPassword){

      // Reset password
      let mutation = new GraphQLMutation({
        'mutationType': "forgotPassword",
        'fields': [
          'success',
          'message',
          'errors { key, value }',
        ],
        'variables': {
          'login':{'type':'String!', 'value': loginValue},
          'url':{'type':'String!', 'value': 'https://'+window.location.hostname + '/auth?token=__TOKEN__'},
        },
        'success': function(data){
          if(data.hasOwnProperty('message')) {
            self.setState({
              ...self.state,
              message: data.message,
            })
          }
        },
        'error': function(message, xhr){
          if(message) {
            self.setState({
              ...self.state,
              message: message,
            })
          }
        },
      })

      mutation.execute()

    }else{

      // Login
      let mutation = new GraphQLMutation({
        'mutationType': "login",
        'fields': [
          'success',
          'message',
          'token',
          'errors { key, value }',
        ],
        'variables': {
          'login':{'type':'String!', 'value': loginValue},
          'password':{'type':'String!', 'value': passwordValue},
        },
        'success': function(data){
          if(data.hasOwnProperty('token')) {
            localStorage.setItem('access_token', data.token);
          }
          if(data.hasOwnProperty('message')) {
            self.setState({
              ...self.state,
              message: data.message,
            })
          }
          setTimeout(() => {
            window.location.replace("/dash");
          },600)
        },
        'error': function(message, xhr){
          if(message) {
            self.setState({
              ...self.state,
              message: message,
            })
          }
        },
      })

      mutation.execute()

    }

  }

  render() {
    return (
      <>
        <Helmet>
          <title>Authentification | Restofuté</title>
          <meta name="description" content="Identification à votre espace Restofuté" />
        </Helmet>  


        <Col id="auth" xs="12" sm="12" lg="5">

          <div id="auth_logo"><img src="./img/logo.png" alt="Restofuté" /></div>
          <div className="auth_text">
            <h1>Bonjour,</h1>
            <p></p>
            <p><b>{this.state.message}</b></p>
          </div>
          <div className="auth-form-container">
            <form className="auth-form" onSubmit={this.handleSubmit}>
              <div className="auth-form-content">
                <div className="form-group mt-3">
                  <label>Adresse email</label>
                  <input
                    type="text"
                    name="email"
                    className="form-control mt-1"
                    placeholder="Email"
                  />
                </div>
                {!this.state.resetPassword &&       
                  <div className="form-group mt-3">
                    <label>Mot de passe</label>
                    <input
                      type="password"
                      name="password"
                      className="form-control mt-1"
                      placeholder="Mot de passe"
                    />
                  </div>
                }
                
                <div className="d-flex gap-2 mt-3 justify-content-end">
                  <button type="submit" className="btn btn-primary">
                  {this.state.resetPassword ? 'Renvoyer' : 'Identification'}
                  </button>
                </div>
                <p className="forgot-password text-right mt-2">
                  <a onClick={this.changeView} href="/#">{this.state.resetPassword ? 'Connexion' : 'Mot de passe oublié'}</a>
                </p>
              </div>
            </form>
          </div>

        </Col>

        <Col id="auth_splash" xs="12" sm="12" lg="7">
         
        </Col>

      </>
    );
  }
}

export default Auth;