import React, { useState, useEffect } from "react";
import GraphQLQuery from "./database/Query.js";
import GraphQLMutation from "./database/Mutation.js";
import Table from "react-bootstrap/Table";
import { prettyFloat } from "../Helper.js";
import configData from "../Config";

const Products = (props) => {
  const [state, setState] = useState({
    terms: 0,
    bookmarksView: true,
    list: [],
    originalList: [],
    loading: false,
    filterKeyword: '',
    filterCategory: 0,
    filterBookmarkOnly: true,
  });

  const [displaySupplierInfo, setDisplaySupplierInfo] = useState(false);

  let suppliers = JSON.parse(localStorage.getItem("suppliers"));

  function buildRangeTree(range, rangeTree = {}) {
    if (!range) return null;

    // Si la range actuelle n'est pas encore dans l'arbre, l'ajouter
    if (!rangeTree[range.id]) {
      rangeTree[range.id] = { tid: range.id, name: range.name, childs: [], possibles:[] };
    }

    // Si le parent de la range actuelle existe et n'est pas encore dans l'arbre, l'ajouter
    if (range.parent && !rangeTree[range.parent.id]) {
      rangeTree[range.parent.id] = { tid: range.parent.id, name: range.parent.name, childs: [], possibles: [range.parent.id] };
    }

    // Si le parent de la range actuelle existe, ajouter la range actuelle à ses enfants
    if (range.parent) {
      rangeTree[range.parent.id].childs[range.id] = rangeTree[range.id];

      if(!rangeTree[range.parent.id].possibles.includes(range.id)){
        rangeTree[range.parent.id].possibles.push(range.id);
      }
    }

    // Continuer à construire l'arbre avec le parent de la range actuelle
    return buildRangeTree(range.parent, rangeTree);
  }

  function getRangeTree(products) {
      let rangeTree = {};

      // Construire l'arbre de ranges pour chaque produit
      for (let edge of products) {
        let product = edge.node.product;
        buildRangeTree(product.range, rangeTree);
      }

      return rangeTree;
  }

  function getFilteredList(keyword,category,bookmarkOnly){
    let list = state.originalList;


    if(keyword){
      list = list.filter((data) => {
        if(data.reference.toLowerCase().includes(keyword.toLowerCase())){
          return true;
        }
        return false;
      });
    }
    if(category){
      
      let possibleRanges = [category];

      let ranges = state.categories;

      let range = ranges[category];
      if (!range) {
        category = 0;
      } else {
        possibleRanges = range.possibles;
      }
      list = list.filter((data) => {
        if (category === 0 || possibleRanges.includes(data.range.id)) {
          return true;
        }
        return false;
      });
    }

    if(bookmarkOnly){
      list = list.filter((data) => {
        return state.bookmarks.includes(data.id);
      });

    }

    return list;
  }

  // Refresh list on props change (for cart updates)
  useEffect(() => {
    console.log(
      "%c ProductList useEffect refresh product list because props change (from parent)",
      "color: #1873a4",
      props
    );

    // Refresh the list with correct cart values (from props passed from App.js, on cart update)
    for (let i = 0; i < state.list.length; i++) {
      state.list[i].count = 0;
    }
    for (let i = 0; i < props.cart.length; i++) {
      let element = props.cart[i];
      let selectedElement = state.list.filter((item) => item.id === element.id);
      if (selectedElement.length) {
        selectedElement[0].count = element.count; // Update state.list of items according to cart values
      }
    }

    setState((s) => ({
      ...s,
      list: state.list,
    }));
  }, [props, state.list, state.loading]);

  // Initial page load (query products & bookmarks)
  useEffect(() => {
    if (props.user.hasOwnProperty("restaurantAssociations")) {
      if (state.originalList.length === 0 && !state.loading) {
        let restaurant = props.user.restaurantAssociations[0].restaurant;
        let restaurantId = restaurant.id;

        document.body.style.cursor = "wait";

        console.log(
          "%c ProductList useEffect (props.cart changed)",
          "color: #1873a4",
          props.cart
        );

        setState((s) => ({
          ...s,
          loading: true,
        }));

        console.warn("Api load product list: loading", restaurantId);

        let datas = [];
        let query = new GraphQLQuery({
          queryType: "restaurantProducts",
          parameters: {
            first: 10000,
            orderBy: "reference",
            dir: "ASC",
            restaurant: restaurantId,
            filter: { visibleByApp: true, orphan: false },
          },
          fields: [
            "product { id reference number unit quantity range { id name parent { id name parent { id name parent { id name  parent { id name } } } } } supplier { id name freeDelivery penalty } technicalFile { url } } ",
            "bookmarked",
            "realPrice",
            "basePrice",
            "realStatus",
            "baseStatus",
          ],
          hasEdges: true,
          addTotalCount: true,
          before: function () {},
          success: function (data) {
            let bookmarks = [];
            let list = [];
            let products = data.edges;

            //identifications des gammes en un tableau hierarchique (categories) et un tableau à plat (ranges)
            let ranges = getRangeTree(products);
            ranges[0] = { tid: 0, name: "MES FAVORIS", childs: [], possibles: []};

            let categories =  Object.values(ranges).filter(range => !Object.values(ranges).some(parent => parent.childs.includes(range)));

            console.warn("Api load product list: ok", products.length);
            for (var i = 0; i < products.length; i++) {
              let product = products[i].node.product;

              product.outOfStock = products[i].node.realStatus === 0;
              if (products[i].node.bookmarked) {
                product.bookmark = true;
                bookmarks.push(product.id);
                list.push(product);
              }

              if (products[i].node.basePrice) {
                product.price = products[i].node.basePrice;
              } else {
                product.price = products[i].node.realPrice;
              }
              datas.push(product);

            }

            // Initialize the list with correct cart values (from props passed from App.js)
            for (let i = 0; i < props.cart.length; i++) {
              let element = props.cart[i];
              let selectedElement = datas.filter(
                (item) => item.id === element.id
              );
              if (selectedElement.length) {
                selectedElement[0].count = element.count; // Update list of items according to cart values
              }
            }

            setState((s) => ({
              ...s,
              loading: false,
              list:list,
              originalList: datas,
              terms: categories,
              ranges: ranges,
              bookmarks: bookmarks,
            }));

            document.body.style.cursor = "auto";
          },
          error: function (message, data) {
            console.error(message, data);
          },
          after: function () {},
        });

        query.execute();
      } else {
        console.warn(
          "%c do not load products, list already populated or loading...",
          "color: #1873a4"
        );
      }
    } else {
      console.warn(
        "%c do not load products, user is not loaded yet (wait for re-render on cart, user, or product loaded)...",
        "color: #1873a4"
      );
    }
  }, [
    props.cart, // re-render on cart count change
    props.user, // allow to check if user is loaded
    state.loading, // allow to check if products are loaded
  ]);

  let onKeywordChange = (e) => {
    
    let category = state.filterCategory;
    let bookmarkOnly = state.filterBookmarkOnly;
    let keyword = e.target.value;

    let list = getFilteredList(keyword,category,bookmarkOnly);
    setState((s) => ({
      ...s,
      filterKeyword: keyword,
      list: list,
    }));
    
  };

  let onTermChange = (e) => {
    let category = parseInt(e.target.value);
    let keyword = state.filterKeyword;
    let bookmarkOnly = state.filterBookmarkOnly;

    if (category === 0) {
      bookmarkOnly = true;
    }

    let list = getFilteredList(keyword,category,bookmarkOnly);
    setState((s) => ({
      ...s,
      filterCategory: category,
      list: list,
      filterBookmarkOnly: bookmarkOnly,
    }));

  };

  let onBookmarkViewChange = (e) => {
    let category = state.filterCategory;
    let keyword = state.filterKeyword;
    let filterBookmarkOnly = state.filterBookmarkOnly;

    if(category !== 0){
      //toggle filterBookmarkOnly only if category is not 0
      filterBookmarkOnly = !filterBookmarkOnly;
    }

    let list = getFilteredList(keyword,category,filterBookmarkOnly);

    setState((s) => ({
      ...s,
      filterBookmarkOnly: filterBookmarkOnly,
      list: list,
    }));

  };

  let onBookmarkChange = (e) => {
    let button = e.target.closest("button");

    let wasBookmarked = parseInt(button.dataset.value);
    let id = parseInt(button.dataset.id);
    let bookmarks = state.bookmarks;

    
    let mutation = "SubmitRestaurantProductBookmarks";
    if (wasBookmarked){
      mutation = "DeleteRestaurantProductBookmarks";

      bookmarks=bookmarks.filter((item) => item !== id);
    }else{

      bookmarks.push(id);
    }
    
    let restaurantId = props.user.restaurantAssociations[0].restaurant.id;

    let queryBookmarks = new GraphQLMutation({
      mutationType: mutation,
      variables: {
        restaurant: { type: "Int!", value: restaurantId },
        products: { type: "[Int]", value: [id] },
        //'removeOthers':{'type':'Boolean', 'value': true},
      },
      fields: ["success", "message", "errors { key, value }"],
      success: function (data) {
        if (data.success) {
          console.warn("Api save bookmarks list: ok", bookmarks, data);

          setState((s) => ({
            ...s,
            bookmarks: bookmarks,
          }));
      
        } else {
          console.error("Api save bookmarks list: error", data);
        }
      },
      error: function (message, data) {
        console.error(message, data);
      },
      after: function () {},
    });
    queryBookmarks.execute();
  };

  const toggleSupplierInfo = () => {
    setDisplaySupplierInfo(!displaySupplierInfo);
  };

  const getDayName = (dayNumber) => {
    const daysOfWeek = [
      "lundi",
      "mardi",
      "mercredi",
      "jeudi",
      "vendredi",
      "samedi",
      "dimanche",
    ];

    return daysOfWeek[dayNumber - 1];
  };

  const exportExcel = () => {
    let restaurantId = props.user.restaurantAssociations[0].restaurant.id;
    const excelExportMutation = new GraphQLMutation({
      mutationType: "ExportRestaurantProductBookmark",
      variables: {
        restaurant: {
          value: restaurantId,
          type: "Int!",
        },
        type: {
          value: "excel",
          type: "String!",
        },
      },
      fields: [
        "success",
        "message",
        "file { url id }",
        "errors { key, value }",
      ],
      success: function (data) {
        if (data.success) {
          let url = configData.API_URL + data.file.url;

          const link = document.createElement("a");
          link.href = url;
          link.target = "_blank";
          link.download = "export.xlsx";
          link.setAttribute(
            "type",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          );
          link.setAttribute("charset", "utf-8");
          link.click();
        } else {
          console.error("Erreur d'export Excel", data.message);
        }
      },
      error: function (message, data) {
        console.error(message, data);
      },
    });

    excelExportMutation.execute();
  };

  const print = () => {
    let restaurantId = props.user.restaurantAssociations[0].restaurant.id;
    const printMutation = new GraphQLMutation({
      mutationType: "ExportRestaurantProductBookmark",
      variables: {
        restaurant: {
          value: restaurantId,
          type: "Int!",
        },
        type: {
          value: "pdf",
          type: "String!",
        },
        includeHeader: {
          value: true,
          type: "Boolean",
        },
      },
      fields: [
        "success",
        "message",
        "file { url id }",
        "errors { key, value }",
      ],
      success: function (data) {
        if (data.success) {
          let url = configData.API_URL + data.file.url;
          const link = document.createElement("a");
          link.href = url;
          link.target = "_blank";
          link.click();
        } else {
          console.error("Erreur d'impression", data);
        }
      },
      error: function (message, data) {
        console.error(message, data);
      },
    });

    printMutation.execute();
  };

  let listItems = [];
  if (state.list) {
    listItems = state.list.map(function (d, index) {
      let price = prettyFloat(d.price);
      let condPrice = prettyFloat(d.price * d.quantity);
      let unitPrice = prettyFloat(parseFloat(d.price) / parseFloat(d.quantity));
      let total = prettyFloat(d.price * d.count);
      let url = "";
      if (d.technicalFile && d.technicalFile.url) {
        url = configData.API_URL + d.technicalFile.url;
      }

      return (
        <tr
          className={"d-flex d-lg-table-row"}
          data-selected={d.count > 0 ? "selected" : ""}
          key={index}
          data-highlight={d.highlight}
        >
          <td>
            <button
              onClick={onBookmarkChange}
              data-id={d.id}
              data-value={d.bookmark ? 1 : 0}
            >
              {d.bookmark ? (
                <img alt="" src="./img/favon.svg" />
              ) : (
                <img alt="" src="./img/favoff.svg" />
              )}
            </button>
          </td>
          <td>
            <span className="small d-lg-none">Référence : </span>
            {d.number}
          </td>
          <td>
            <span className="small d-lg-none">Produit : </span>
            {d.technicalFile && d.technicalFile.url ? (
              <a href={url} target="_blank">
                {d.reference}
              </a>
            ) : (
              d.reference
            )}
            {d.outOfStock ? <span className="info-stock">hors-stock</span> : ""}
          </td>
          <td>
            <span className="small d-lg-none">Gamme : </span>
            {d.range.name}
          </td>
          <td>
            <span className="small d-lg-none">Fournisseur : </span>
            {d.supplier.name}
          </td>
          <td>
            <span className="small d-lg-none">Cond. : </span>
            {d.quantity} {d.unit}
          </td>
          <td>
            <span className="small d-lg-none">Prix cond. : </span>
            {price} €
          </td>
          <td>
            <span className="small d-lg-none">Prix U. : </span>
            {unitPrice} € / {d.unit}
          </td>
          <td className="td-quantity">
            <span className="small d-lg-none">Qté. : </span>
            <button
              onClick={props.updateProductCart}
              className="removefromcart"
              data-price={d.price}
              data-name={d.reference}
              data-id={d.id}
              data-count={d.count}
              data-vendor={d.supplier.name}
              data-vendordelivery={d.supplier.freeDelivery}
              data-vendorpenalty={d.supplier.penalty}
              data-vendorid={d.supplier.id}
            >
              <img alt="minus" src="./img/minus.svg" width={20} />
            </button>
            <input
              type="text"
              className="freeInput"
              value={d.count || 0}
              data-price={d.price}
              data-name={d.reference}
              data-id={d.id}
              data-count={d.count}
              data-vendor={d.supplier.name}
              data-vendordelivery={d.supplier.freeDelivery}
              data-vendorpenalty={d.supplier.penalty}
              data-vendorid={d.supplier.id}
              onChange={props.updateProductCart}
            />
            <button
              onClick={props.updateProductCart}
              className="addtocart"
              disabled={d.outOfStock}
              data-price={d.price}
              data-name={d.reference}
              data-id={d.id}
              data-count={d.count}
              data-vendor={d.supplier.name}
              data-vendordelivery={d.supplier.freeDelivery}
              data-vendorpenalty={d.supplier.penalty}
              data-vendorid={d.supplier.id}
            >
              <img alt="plus" src="./img/plus.svg" width={20} />
            </button>
          </td>
          <td>
            <span className="small d-lg-none">Total : </span>
            {total} €
          </td>
        </tr>
      );
    });
  }
  let listTerms = [];
  if (state.terms) {
    let categories = state.terms;
    //sort categories by name but let element with tid==0 first
    categories.sort(function(a,b){
      if(a.tid==0){
        return -1;
      }
      if(b.tid==0){
        return 1;
      }
      return a.name.localeCompare(b.name); 
    });
    let offset = "\u00A0\u00A0\u00A0\u00A0";
    let keys = [];
    listTerms = categories.map(function (d, index) {
      let options = [];
      let rangeKey = "RANGE"+d.tid;
      keys.push(rangeKey);
      options.push(
        <option value={d.tid} key={rangeKey} style={{fontWeight: 'bold'}}>
          {d.name}
        </option>
      );

      if (d.childs != undefined) {
        let childsKeys = Object.keys(d.childs);
        if (childsKeys.length > 0) {
          childsKeys = childsKeys.sort(function (a, b) {
            let aObject = d.childs[a];
            let bObject = d.childs[b];
            return ("" + aObject.name).localeCompare(bObject.name);
          });

          for (var i in childsKeys) {
            let childIndex = childsKeys[i];
            let child = d.childs[childIndex];
            //set random string key
            let childKey = "RANGE"+child.tid;
            keys.push(childKey);

            options.push(
              <option value={child.tid} parent={d.tid} key={childKey}>
                {offset}
                {child.name}
              </option>
            );

            if (child.childs != undefined) {
              let subChildsKeys = Object.keys(child.childs);
              if (subChildsKeys.length > 0) {
                subChildsKeys = subChildsKeys.sort(function (a, b) {
                  let aObject = child.childs[a];
                  let bObject = child.childs[b];
                  return ("" + aObject.name).localeCompare(bObject.name);
                });
                for (var j in subChildsKeys) {
                  let subChildIndex = childIndex + "-" + j;
                  let subchildTid = subChildsKeys[j];
                  let subchild = child.childs[subchildTid];
                  let subChildKey = "RANGE"+subchild.tid;
                  keys.push(subChildKey);
      
                  options.push(
                    <option
                      value={subchild.tid}
                      parent={child.tid}
                      grandparent={d.tid}
                      key={subChildKey}
                    >
                      {offset}
                      {offset}
                      {subchild.name}
                    </option>
                  );
                }
              }
            }
          }
        }
      }
      return options;
    });
  }

  return (
    <div>
      <div className="info-delivery" onClick={toggleSupplierInfo}>
        <p>INFORMATIONS DES JOURS DE COMMANDE ET DE LIVRAISON</p>
        <img
          src={"./img/vector.png"}
          className={displaySupplierInfo ? "rotate-info" : ""}
          alt="flèche"
        />
      </div>
      {displaySupplierInfo && (
        <div className="supplier-info">
          <ul>
            {suppliers.map((supplier, index) => (
              <li className="nameSupplierHour" key={index}>
                Vos commandes chez : {supplier.name}
                {supplier.deliveries.length > 0 && (
                  <ul>
                    {supplier.deliveries.map((delivery, indexDelivery) => (
                      <li key={indexDelivery}>
                        <b>
                          Commande au plus tard le {getDayName(delivery.maxDay)}{" "}
                          à {delivery.maxHour} pour livraison le{" "}
                          {getDayName(delivery.day)} à {delivery.hour}
                        </b>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className="ProductForm">
        <select id="tid" name="tid" value={state.filterCategory} onChange={onTermChange}>
          {listTerms}
        </select>
        <input
          className="loupe"
          id="keyword"
          type="text"
          placeholder="Rechercher un produit"
          value={state.filterKeyword}
          onChange={onKeywordChange}
        />
        <div className="buttons">
          <button
            id="bookmarks"
            className={
              "btn btn-secondary " +
              (state.filterBookmarkOnly ? "active" : "inactive")
            }
            onClick={onBookmarkViewChange}
          >
            <img alt="" src="./img/favoris.svg" width={40} />
            Favoris {state.filterBookmarkOnly ? "activés" : "désactivés"}
          </button>
          <button
            className="btn btn-secondary btn-p"
            onClick={(e) => {
              let list = getFilteredList(null,0,true);
              setState((s) => ({
                ...s,
                filterKeyword: '',
                filterCategory: 0,
                filterBookmarkOnly: true,
                list: list,
              }));
            }}
          >
            <img
              src="../img/reinit.svg"
              width={35}
              alt="Reinitialiser"
              className="mr-2 d-inline-block btn-img"
              title="Reinitialiser"
            />
          </button>
          <button className="btn btn-secondary btn-p" onClick={exportExcel}>
            <img
              src="../img/excel.svg"
              width={35}
              alt="excel"
              className="mr-2 d-inline-block btn-img"
              title="Export Excel"
            />
          </button>
          <button className="btn btn-secondary btn-p" onClick={print}>
            <img
              src="../img/print.svg"
              width={35}
              alt="imprimer"
              className="mr-2 d-inline-block btn-img"
              title="Imprimer"
            />
          </button>
        </div>
      </div>
      <Table striped>
        <thead>
          <tr className="d-none d-lg-table-row">
            <th></th>
            <th>Référence</th>
            <th>Produit</th>
            <th>Gamme</th>
            <th>Fournisseur</th>
            <th>Cond.</th>
            <th>Prix Cond.</th>
            <th>Prix U.</th>
            <th>Qte</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {state.loading ? (
            <tr>
              <td className="bg-transparent">Chargement...</td>
            </tr>
          ) : null}
          {listItems}
        </tbody>
      </Table>
    </div>
  );
};
export default Products;
