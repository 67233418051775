import React, { Component } from "react"
import configData from "../Config";
import Col from 'react-bootstrap/Col';


class News extends Component {
    
    
    render() {

        const style = {backgroundImage: 'url(./img/actu_banner.png)'}


        return (
            <>

                <Col id="dash-news">
                    <p>Actualités</p>
                    <div className="wrap">
                        <a href={ configData.NEWS_URL } className="news-banner row" style={style}>
                            <div className="col-sm-6">

                            </div>
                            <div className="col-sm-6 d-flex justify-content-center align-items-start flex-column">
                                <span><b>{ configData.NEWS_INFOS }</b></span>
                                <button className="btn btn-primary mt-4">{ configData.NEWS_BUTTON }</button>
                            </div>
                            
                        </a>
                    </div>
                 
                </Col>

                   
            </>
        );
    }
}

export default News;


/*

// Old news list :

<div className="wrap">
    <a href="http://restofute.com/news/12" className="news-item" style={style}>
        <span className="picto"><img alt="picto" src="./img/picto_round_hand.png" /></span>
        <span><b>Restofuté recrute</b>, devenez développeur web</span>
    </a>

    <a href="http://restofute.com/news/12" className="news-item" style={style}>
        <span className="picto"><img alt="picto" src="./img/picto_round_hand.png" /></span>
        <span>Tensions sur les prix des matières premières</span>
    </a>

    <a href="http://restofute.com/news/12" className="news-item" style={style}>
        <span className="picto"><img alt="picto" src="./img/picto_round_hand.png" /></span>
        <span>Notre nouveau partenaire, Hector le Collector recycle vos biodéchets</span>
    </a>
</div>
<div className="link-bottom"><a href="http://restofute.com/news">Voir plus d'articles +</a></div>

*/