import React, { Component } from "react"
import { Helmet } from 'react-helmet';
import Col from 'react-bootstrap/Col';
//import '../styles/Profile.scss';
//import GraphQLMutation from '../components/database/Mutation.js';

class Validate extends Component { 

  constructor(props) {
    super(props);
    this.state = { message : 'Votre commande Restofuté est validée, une notification vous sera envoyée rapidement.'}
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Validation de votre commande | Restofuté</title>
          <meta name="description" content="Validation de commande" />
        </Helmet>  

        <Col id="profile" xs="12" sm="12" lg="10">
          <p><b>{this.state.message}</b></p>
          <div className="mascotte">
            <img src="./img/mascotte.png" alt="mascotte" />
          </div>
        </Col>

      </>
    );
  }
}

export default Validate;