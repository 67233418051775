import React, { useState, useEffect,useRef } from "react";
import DateRangeComponent from './theme/DateRangeComponent.js'
import { format, parseISO } from 'date-fns';
import GraphQLQuery from './database/Query.js';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import { prettyFloat } from '../Helper.js';

const OrdersList = (props) => {
    
    const [state, setState] = useState({
        query:'',
        loading: false,
        fromDate:'',
        toDate:'',
        list:[],
        originalList:[],
        modalInfo:false,
        showDetail:false,
    })

    const handleShow = (e) =>{

        document.body.style.cursor = 'wait'

        let id = e.target.textContent.trim()
        let query = new GraphQLQuery({ 
            'queryType': 'restaurantOrder',
            'parameters': {
                'id' : parseInt(id)
            },
            'fields': [
                'id',
                'supplier { id name }',
                'deliveredAt',
                'total',
                'totalTTC',
                'createdAt',
                'productAssociations { packagingQuantity quantity product { id reference number unit quantity price } }',
            ],
            'before': function(){
            },
            'success': function(data){ 

                let order = data
                let products = data.productAssociations.map(function(p, index){

                    let packagingQuantity = prettyFloat(p.packagingQuantity);
                    let unitPrice = prettyFloat(p.product.price);
                    let quantity = prettyFloat(p.quantity);
                    let price = prettyFloat(parseFloat(p.product.price) * parseFloat(p.packagingQuantity));
                    let total =  prettyFloat(parseFloat(p.product.price) * parseFloat(p.quantity) * parseFloat(p.packagingQuantity));
                    return <tr key={index}>
                        <td><span className="small d-lg-none">Référence : </span>{p.product.number}</td>
                        <td><span className="small d-lg-none">Désignation : </span>{p.product.reference}</td>
                        <td><span className="small d-lg-none">Cond : </span>{packagingQuantity} {p.product.unit}</td>
                        <td><span className="small d-lg-none">Prix unit. : </span>{unitPrice} € </td>
                        <td><span className="small d-lg-none">Prix : </span>{price} €</td>
                        <td><span className="small d-lg-none">Quantités : </span>{quantity}</td>
                        <td><span className="small d-lg-none">Total : </span>{total} €</td>
                    </tr>
                })
                let total = prettyFloat(order.total);
                let totalTTC = prettyFloat(order.totalTTC);
                let deliveredAt = order.deliveredAt;
                if(deliveredAt === null){
                    deliveredAt = ''
                }else{
                    deliveredAt = 'Livraison prévue pour le ' + format(parseISO(order.deliveredAt), "dd/MM/yyyy à HH:mm")
                }
                let render = <>
                    <h1>Commande {order.id}</h1>
                    <Table striped>
                        <thead>
                            <tr className="d-none d-lg-table-row">
                                <th>Référence</th>
                                <th>Désignation</th>
                                <th>Cond</th>
                                <th>Prix unit.</th>
                                <th>Prix</th>
                                <th>Quantités</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                        {products}
                        </tbody>
                    </Table>
                    <p>
                        Commande du {`${format(parseISO(order.createdAt), "dd/MM/yyyy à HH:mm")}`}
                        <br />
                        {deliveredAt}
                    </p>
                    <p className="total">
                        <span className="small">Total de la commande</span><br />
                        <span> {total} € H.T.</span><br />
                        <span> {totalTTC} € T.T.C.</span>
                        <div className="mt-2">
                            <button className="btn btn-transparent" onClick={ () => {
                                window.print()
                            } }><img alt="" src="./img/print.svg" /></button>
                        </div>
                    </p>
                    
                </>
                
                setState(s => ({
                    ...s,
                    modalInfo: render,
                    showDetail: true,
                }))

                setTimeout(() => {
                    document.body.style.cursor = 'auto'
                }, 200) 

            },
            'error': function(message, data){
                console.error(message,data)
            },
            'after': function(){
            }
        });

        query.execute();

    }

    const handleClose = () => {
        setState(s => ({
            ...s,
            showDetail: false,
        }))
    }

    let today = new Date();
   
    let thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(today.getDate() - 31);
   
    let thirtyDaysAgoFormatted = formatDate(thirtyDaysAgo);
    
    let filterDay = `>= ${thirtyDaysAgoFormatted}`;

    function formatDate(date) {
    let year = date.getFullYear();
    let month = String(date.getMonth() + 1).padStart(2, '0');
    let day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
    }

    // Initial route load
    useEffect(() => {

        setState(s => ({
            ...s,
            loading: true,
        }))

        if(props.user.hasOwnProperty('restaurantAssociations')){
        
            let restaurantId = props.user.restaurantAssociations[0].restaurant.id
            
            let datas = []
            let query = new GraphQLQuery({ 
                'queryType': 'restaurantOrders',
                'parameters': {
                    'first': 10000,
                    'orderBy': 'createdAt',
                    'dir': 'DESC',
                    'filter' : {'restaurant':restaurantId, 'status':1,'createdAt':filterDay}
                },
                'fields': [
                    'id',
                    'supplier { id name }',
                    'total',
                    'totalTTC',
                    'createdAt',
                    'status',
                    'deliveredAt',
                    'deliveryNumber',
                    'restaurant { supplierAssociations { supplier { id } customerCodes { code } } }'
                ],
                'hasEdges': true,
                'addPageInfo': false,
                'addTotalCount': true,
                'before': function(){
                },
                'success': function(data){ 
                    let orders = data.edges
                    for (var i = 0; i < orders.length; i++){
                        let order = orders[i].node;
                        let bl = order.deliveryNumber;
                        if(!bl){
                            bl = '';
                        }
                        let supplierId = order.supplier.id;

                        let localStorageCustomerCodeKey = 'customerCodeForSupplier'+supplierId;
                        let codeSupplier = localStorage.getItem(localStorageCustomerCodeKey);
                        if(codeSupplier === null){
                            codeSupplier = '';
                            for(var i in order.restaurant.supplierAssociations){
                                let supplierAssociation = order.restaurant.supplierAssociations[i];
                                if(supplierAssociation.supplier.id == supplierId){
                                    for(var c in supplierAssociation.customerCodes){
                                        let customerCode = supplierAssociation.customerCodes[c];
                                        if(customerCode.code){
                                            codeSupplier = customerCode.code;
                                            console.log("suppliercode found from request", codeSupplier);
                                        }
                                    }
                                }
                            }
                            localStorage.setItem(localStorageCustomerCodeKey,codeSupplier);
                        }
                        let item = {
                            'id':order.id, 
                            'date':order.createdAt.replace(' ', 'T'),
                            'bl': bl,
                            'supplier': order.supplier,
                            'codeSupplier': codeSupplier,
                            'shippingDate' : order.deliveredAt !== null ? order.deliveredAt.replace(' ', 'T') : order.createdAt.replace(' ', 'T'),
                            'total' : order.total,
                            'totalTTC': order.totalTTC
                        }
                        datas.push(item)
                    }

                    setState(s => ({
                        ...s,
                        loading: false,
                        list:datas,
                        originalList:datas,
                    }))

                },
                'error': function(message, data){
                    console.error(message,data)
                },
                'after': function(){
                }
            });

            query.execute();

        }

    }, [props.user])

    let onKeyworkChange = (e) => {

        const results = state.originalList.filter(data => {
            if(state.fromDate !== '' && state.toDate !== ''){
                let date = new Date(data.date);
                return date >= state.fromDate && date <= state.toDate && data.bl.toLowerCase().includes(e.target.value.toLowerCase())
            }
            else{
                let bl = '';
                if(data.bl){
                    bl = data.bl;
                }
                let value = '';
                if(e.target.value){
                    value = e.target.value;
                }
                return bl.toLowerCase().includes(value.toLowerCase())
            }
        })
        setState({
            ...state,
            query: e.target.value,
            toDate:state.toDate,
            list: results
        })
    }

    let onDateChange = (e) => {

        let fromDate = e.selection.startDate;
        let toDate = e.selection.endDate;
        const results = state.originalList.filter(data => {
            let date = new Date(data.date)
            return date >= fromDate && date <= toDate && data.bl.toLowerCase().includes(state.query.toLowerCase())
        })
        setState({
            ...state,
            fromDate: fromDate,
            toDate: toDate,
            list: results
        })

    }

    let totalHT = 0
    let totalTTC = 0

    let listItems = [];
    if(state.list){
        listItems = state.list.map(function(d, index){
            let supplier = d.supplier.name;
            if(d.codeSupplier){
                supplier += ' (code client : '+d.codeSupplier+')';
            }
            totalHT += parseFloat(d.total); 
            totalTTC += parseFloat(d.totalTTC); 

            return <tr key={index} className={!d.bl ? 'waiting' : 'valid'}>
                <td><span className="small d-lg-none">Date : </span>{`${format(parseISO(d.date), "dd/MM/yyyy")}`}</td>
                <td onClick={handleShow} style={{cursor:'pointer'}}><span className="small d-lg-none">N° : </span><b>{d.id}</b></td>
                <td><span className="small d-lg-none">N° BL : </span>{d.bl ? d.bl : 'En attente'}</td>
                <td><span className="small d-lg-none">Fournisseur : </span>{supplier}</td>
                <td><span className="small d-lg-none">Livraison : </span>{`${format(parseISO(d.shippingDate), "dd/MM/yyyy")}`}</td>
                <td><span className="small d-lg-none">Total H.T: </span>{prettyFloat(d.total)} €</td>
                <td><span className="small d-lg-none">Total T.T.C: </span>{prettyFloat(d.totalTTC)} €</td>
            </tr>
        })
    }
 
    return (
        <div>
            <div className="form orderForm form-filters">
                <input id="keyword" type="text" placeholder="N° BL" value={state.query} onChange={onKeyworkChange} />
                <DateRangeComponent onDateChange={onDateChange} />
            </div>
            <Table striped>
              <thead>
                <tr className="d-none d-lg-table-row">
                  <th>Date</th>
                  <th>N° commande</th>
                  <th>N° BL</th>
                  <th>Fournisseur</th>
                  <th>Date de livraison</th>
                  <th>Total H.T</th>
                  <th>Total T.T.C</th>
                </tr>
              </thead>
              <tbody>
              {state.loading ? <tr><td>Chargement...</td></tr> : null}
              {listItems}
              </tbody>
            </Table>
            <p>Total H.T: {prettyFloat(totalHT)} € </p>
            <p>Total T.T.C: {prettyFloat(totalTTC)} € </p>

            <Modal show={state.showDetail} onHide={handleClose}>
                <Modal.Header closeButton>

                </Modal.Header>
                <Modal.Body>
                    {state.modalInfo}
                    
                </Modal.Body>

            </Modal>

        </div>
    )
};
export default OrdersList;