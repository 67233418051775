import React, { Component } from "react"
import { Helmet } from 'react-helmet';
import Col from 'react-bootstrap/Col';
import '../styles/Profile.scss';
import GraphQLMutation from '../components/database/Mutation.js';

class Profile extends Component { 

  constructor(props) {
    super(props);
    this.state = { message : ''}
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {

    event.preventDefault();

    if(!this.props.user.id) window.location.replace("/auth");

    let form = new FormData(event.target)
    let newsletterValue = form.get('newsletter')
    form.append('newsletterRefused', !newsletterValue ? true : false)

    if(!form.get('password')){
      form.delete('password')
      form.delete('passwordConfirm')
    }

    let self = this

    // Login
    let mutation = new GraphQLMutation({
      'mutationType': "SubmitUser",
      'fields': [
        'success',
        'message',
        'errors { key, value }',
        'user { token } ',
      ],
      'variables': {
        'user':{'type':'Int!', 'value': this.props.user.id},
      },
      'data' : form,
      'success': function(data){

        let actualToken = localStorage.getItem('access_token');
        if(actualToken !== data.user.token){
          localStorage.setItem('access_token', data.user.token);
        }

        if(data.hasOwnProperty('message')) {
          self.setState({
            ...self.state,
            message: data.message,
          })

          setTimeout(() => {
            window.location = "/dash"
          }, 2000)
        }
      },
      'error': function(message, xhr){
        if(message) {
          self.setState({
            ...self.state,
            message: message,
          })
        }
      },
    });
    mutation.execute();
    
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Mon profil | Restofuté</title>
          <meta name="description" content="Mon profil Restofuté" />
        </Helmet>  

        
        <Col id="profile" xs="12" sm="12" lg="10">

          <h1>Mon profil</h1>
          <p>Modifier mon profil Restofuté</p>

          <p><b>{this.state.message}</b></p>

          <div className="profile-form-container">
            <form autoComplete="off" className="profile-form form" onSubmit={this.handleSubmit}>
              
              <div className="profile-form-content">
                <div className="form-group mt-3">
                  <label>Adresse email</label>
                  <input
                    type="text"
                    name="email"
                    className="form-control mt-1"
                    placeholder="Adresse email"
                    autoComplete="off"
                    defaultValue={this.props.user.email}
                  />
                </div>
                <div className="form-group mt-3">
                  <label>Nom</label>
                  <input
                    type="text"
                    name="lastName"
                    autoComplete="lastName"
                    className="form-control mt-1"
                    placeholder="Nom"
                    defaultValue={this.props.user.lastName}
                  />
                </div>
                <div className="form-group mt-3">
                  <label>Prénom</label>
                  <input
                    type="text"
                    name="firstName"
                    autoComplete="firstname"
                    className="form-control mt-1"
                    placeholder="Prénom"
                    defaultValue={this.props.user.firstName}
                  />
                </div>
                <div className="form-group mt-3">
                  <label>Mot de passe</label>
                  <input
                    type="password"
                    name="password"
                    autoComplete="new-password"
                    className="form-control mt-1"
                    placeholder="Mot de passe"
                  />
                </div>
                <div className="form-group mt-3">
                  <label>Confirmation du mot de passe</label>
                  <input
                    type="password"
                    name="passwordConfirm"
                    autoComplete="passwordConfirm"
                    className="form-control mt-1"
                    placeholder="Confirmez votre mot de passe"
                  />
                </div>
                <div className="form-group mt-3">
                  
                  <input
                    type="checkbox"
                    name="newsletter"
                    className="form-check-input mt-1"
                  />
                  <label className="form-check-label">Abonnement à la newsletter</label>
                </div>
                <div className="d-flex gap-2 mt-3 justify-content-end">
                  <button type="submit" className="btn btn-primary">
                    Sauvegarder
                  </button>
                </div>

              </div>
            </form>
          </div>

        </Col>

      </>
    );
  }
}

export default Profile;