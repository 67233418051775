import {Component} from "react";
import axios from 'axios';
import {NavLink} from 'react-router-dom'

export default class MenuJson extends Component {

    constructor(props) {
        super(props);
        this.state = { menu : null };
        this.getJson();
    }

    getJson() {

        let self = this;
        axios.get('menu.json')
        .then(function (response) {
          // handle success
          self.setState({ menu : response.data.menu.popup.menuitem });
        })
        .catch(function (error) {
          // handle error
          console.error(JSON.stringify(error));
        })
        .finally(function () {
          // always executed
        });
    }
    
    render() {

        if(this.state.menu == null){
            return (
              <>
                <p className="d-flex justify-content-center">Chargement...</p>
              </>
            );
        }else{
            let items = this.state.menu;
            let itemList=[];
            items.forEach((item,index)=>{
              itemList.push( <li key={index}><NavLink onClick={() => {document.getElementById("burgerbutton").click()}} className={(navData) => (navData.isActive ? "active" : 'none')} to={item.href}>
                <span><img alt="picto" src={item.picto} /></span>
                <span className="active"><img alt="picto" src={item.pictoactive} /></span>
                <div className="text">{item.value}</div></NavLink></li>)
            })

            return (
              <>
                <ul>
                  {itemList}
                </ul>
              </>
            );
        }
    }
}