import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import "./styles/App.scss";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";

import configData from "./Config";
import Nav from "./components/theme/Nav";
import Cart from "./components/Cart";
import GraphQLQuery from "./components/database/Query.js";

import { Routes, Route, Navigate } from "react-router-dom";
import Auth from "./routes/Auth";
import Dash from "./routes/Dash";
import Order from "./routes/Order";
import Product from "./routes/Product";
import Profile from "./routes/Profile";
import Invoice from "./routes/Invoice";
import Validate from "./routes/Validate";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menuOpened: window.innerWidth < 780 ? false : true,
      cartOpened: false,
      user: false,
      cart: {},
      sideBar: false
    };

    this.bridgeProps = this.bridgeProps.bind(this);
    this.toggleCart = this.toggleCart.bind(this);
    this.toggleSideBar = this.toggleSideBar.bind(this);
  }

  getCart(user) {
    let self = this;
    let restaurantId = user.restaurantAssociations[0].restaurant.id;

    let query = new GraphQLQuery({
      queryType: "restaurantOrders",
      parameters: {
        filter: { restaurant: restaurantId, status: 2 },
      },
      fields: [
        "id",
        "supplier { id name freeDelivery penalty }",
        "productAssociations { product { id reference number unit quantity price } }",
        "total",
        "totalTTC",
        "createdAt",
        "status",
        "deliveredAt",
      ],
      hasEdges: true,
      addPageInfo: false,
      addTotalCount: true,
      before: function () {},
      success: function (data) {
        let datasCart = [];

        let orders = data.edges;
        for (let i = 0; i < orders.length; i++) {
          let order = orders[i].node;
          for (let i = 0; i < order.productAssociations.length; i++) {
            let product = order.productAssociations[i].product;
            datasCart.push({
              id: product.id,
              name: product.reference,
              count: product.quantity,
              price: product.price,
              vendor: order.supplier.name,
              vendorid: order.supplier.id,
              vendorpenalty: order.supplier.freeDelivery,
              vendordelivery: order.supplier.penalty,
              orderid: order.id,
            });
          }
        }

        self.setState((s) => ({
          ...s,
          cart: datasCart,
        }));
      },
      error: function (message, data) {
        console.error(message, data);
      },
      after: function () {},
    });

    query.execute();
  }

  componentDidMount() {
    // load current user
 
    let self = this;
    let query = new GraphQLQuery({
      queryType: "me",
      fields: [
        "id",
        "login",
        "email",
        "lastName",
        "firstName",
        "restaurantAssociations { restaurant { id name supplierAssociations { supplier { id name } deliveries { orderMaxDay orderMaxHour day hour } nextDelivery } } }",
      ],
      before: function () {},
      success: function (data) {
        let restaurantAssociations = data.restaurantAssociations[0];
        let supplierAssociations =
          restaurantAssociations.restaurant.supplierAssociations;

        let suppliers = [];
        if (supplierAssociations && supplierAssociations.length > 0) {
          for (let supplierAssociation of supplierAssociations) {
            let supplier = supplierAssociation.supplier;
            let supplierDeliveries = supplierAssociation.deliveries;

            let id = supplier.id;
            let name = supplier.name;
            let nextDelivery = null;
            if(supplierAssociation.nextDelivery){
              nextDelivery = new Date(supplierAssociation.nextDelivery);
            }

            let deliveries = [];
            if (supplierDeliveries && supplierDeliveries.length > 0) {
              for (let delivery of supplierDeliveries) {
                deliveries.push({
                  day: delivery.day,
                  maxDay: delivery.orderMaxDay,
                  hour: delivery.hour,
                  maxHour: delivery.orderMaxHour,
                });
              }
            }
            let supplierData = {
              id: supplier.id,
              name: supplier.name,
              deliveries: deliveries,
              nextDelivery: nextDelivery,
            };
            suppliers.push(supplierData);
            console.log("supplier " + supplier.name, supplier, supplierData);
          }
        }

        self.setState((s) => ({
          ...s,
          user: data,
        }));

        suppliers = suppliers.sort((a, b) => a.name - b.name);
        localStorage.removeItem("suppliers");
        localStorage.setItem("suppliers", JSON.stringify(suppliers));

        self.getCart(data);
      },
      error: function (message, data) {
        if (window.location.pathname !== "/auth") {
          localStorage.removeItem("access_token");
          window.location.replace("/auth");
        }
      },
    });

    query.execute();

    if(window.location.href.split('/')[3] === "product"){
      this.setState({
        ...this.state,
        isProductPage: true
      });
    }

    window.addEventListener("beforeunload", this.onBeforeUnload);
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onBeforeUnload);
  }

  onBeforeUnload = (e) => {
    console.error(window.location.pathname);

    if (
      window.location.pathname !== "/auth" &&
      window.location.pathname !== "/validate" &&
      window.location.pathname !== "/profile" &&
      window.location.pathname !== "/dash"
    ) {
      e.preventDefault();
      e.returnValue = "";
    }
  };

  bridgeProps(e) {
    let button = null;
    if (e.target.classList.contains("freeInput")) {
      button = e.target;
    } else {
      button = e.target.closest("button");
    }

    // actions from various components (Cart, Product...)
    if (
      button.classList.contains("addtocart") ||
      button.classList.contains("removefromcart") ||
      button.classList.contains("freeInput")
    ) {
      let currentCount = button.classList.contains("addtocart")
        ? parseInt(button.dataset.count) + 1
        : parseInt(button.dataset.count) - 1;

      if (button.classList.contains("freeInput")) {
        currentCount = button.value;
      }

      let product = {
        id: parseInt(button.dataset.id),
        vendor: button.dataset.vendor,
        vendorid: button.dataset.vendorid,
        vendorpenalty: button.dataset.vendorpenalty,
        vendordelivery: button.dataset.vendordelivery,
        name: button.dataset.name,
        count: currentCount,
        price: button.dataset.price,
      };

      let currentCart = this.state.cart;

      if (currentCount > 0) {
        let itemIsInCart = currentCart.filter((item) => item.id === product.id);
        if (itemIsInCart.length > 0) {
          // if product exist in cart, changes its count
          itemIsInCart[0].count = product.count;
        } else {
          // else add the item in cart
          currentCart.push({
            id: product.id,
            vendor: product.vendor,
            vendorid: product.vendorid,
            vendorpenalty: product.vendorpenalty,
            vendordelivery: product.vendordelivery,
            name: product.name,
            count: currentCount,
            price: product.price,
          });
        }
      } else {
        // remove item from cart
        let removeIndex = currentCart
          .map((item) => item.id)
          .indexOf(product.id);
        if (removeIndex >= 0) currentCart.splice(removeIndex, 1);
      }

      // save cart
      this.setState({
        ...this.state,
        cart: currentCart,
      });
    }
  }

  toggleCart() {
    this.setState({
      ...this.state,
      cartOpened: !this.state.cartOpened,
    });
  }

  toggleSideBar() {
    this.setState(prevState => ({
        sideBar: !prevState.sideBar
    }), () => {
        const body = document.querySelector('.App');
        if (this.state.sideBar && window.location.href.split('/')[3] ) {
            body.classList.add('reduce-side-bar');
        } else {
            body.classList.remove('reduce-side-bar');
        }
    });
}

   
  render() {
    const productsPage = window.location.pathname === "/product";
    const isProductsPage = window.location.pathname === "/product";
    
    return (
      <div
        className={`App ${isProductsPage ? "reduce-side-bar" : ""}`}
      >
        <Helmet>
          <title>Dashboad | Restofuté</title>
          <meta name="description" content="Tableau de bord de votre espace" />
        </Helmet>

        <Container
          fluid
          id="main"
          data-info={this.state.cartOpened ? "cartOpened" : ""}
        >
          <Link className="d-block d-lg-none" id="logomobile" to="/dash">
            <img src="./img/logo.png" alt="Restofuté" />
          </Link>
          <button
            id="cartButton"
            onClick={() =>
              this.setState({ cartOpened: !this.state.cartOpened })
            }
          >
            <img
              className="d-none d-md-block"
              src="./img/picto_panier.svg"
              alt="panier"
              width="27"
              height="27"
            />
            <img
              className="d-md-none d-block"
              src="./img/paniermobile.svg"
              alt="panier"
              width="27"
              height="27"
            />
            <span className="name">Mon panier</span>
            <span>{this.state.cart.length}</span>
          </button>
          <Col id="burger" xs="12" sm="12" md="12">
            <div className="d-md-block d-lg-none">
              <button
                id="burgerbutton"
                onClick={() =>
                  this.setState({ menuOpened: !this.state.menuOpened })
                }
              >
                <img src="./img/burger.svg" alt="burger" />
              </button>
            </div>
          </Col>

          <Col
            id="nav"
            xs="12"
            sm="12"
            md="12"
            lg="2"
            className={`nav-wrapper ${this.state.menuOpened ? "open" : ""}`}
          >
            {productsPage ? (
              <img
                className={`arrow ${this.state.sideBar? "rotate" : ""}`}
                onClick={this.toggleSideBar}
                src="../img/arrow.svg"
                title="Agrandir/Réduire"
                alt="Agrandir/Réduire"
              />
            ) : (
              ""
            )}
            <div className="inner-nav">
              <Link id="logo" to="/dash">
                <img src="./img/logo.png" alt="Restofuté" />
              </Link>

              <div className="profile">
                <div className="profile-info">
                  <div className="name">
                    {this.state.user.firstName} {this.state.user.lastName}
                    <Link className="profile-edit-link" to="/profile">
                      <img
                        src="./img/picto_edit.svg"
                        alt="modifier"
                        width="30"
                        height="30"
                      />
                    </Link>
                  </div>
                  <div className="email">{this.state.user.email}</div>
                  <button
                    onClick={() => {
                      localStorage.removeItem("access_token");
                      window.location.replace("/auth");
                    }}
                  >
                    {configData.DISCONNECT}
                  </button>
                </div>
              </div>

              <div
                className="menu"
                onClick={() =>
                  this.setState({ menuOpened: !this.state.menuOpened })
                }
              >
                <div className="d-lg-none">
                  <img src="./img/close.svg" alt="fermer" />
                </div>
                <Nav />
              </div>

              <div className="link">
                <Link className="contact-link" to={configData.CONTACT_URL}>
                  <img
                    src="./img/nav_profile.png"
                    alt="contact"
                    width="24"
                    height="24"
                  />
                </Link>
                <a href={configData.CONTACT_PHONE} className="mail-link">
                  <img
                    src="./img/nav_phone.png"
                    alt="tel"
                    width="24"
                    height="24"
                  />
                </a>
                <a href={configData.CONTACT_MAIL} className="mail-link">
                  <img
                    src="./img/nav_mail.png"
                    alt="tel"
                    width="24"
                    height="24"
                  />
                </a>
              </div>

              <div className="mascotte">
                <img src="./img/mascotte.png" alt="mascotte" />
              </div>
            </div>
          </Col>
          <div
            id="cartWrapper"
            className={`cart-wrapper ${this.state.cartOpened ? "open" : ""}`}
          >
            <Cart
              updateProductCart={this.bridgeProps}
              user={this.state.user}
              cart={this.state.cart}
              toggleCart={this.toggleCart}
            />
          </div>
          <Routes>
            <Route path="/auth" element={<Auth />} />
            <Route path="/dash" element={<Dash user={this.state.user} />} />
            <Route
              path="/profile"
              element={<Profile user={this.state.user} />}
            />
            <Route path="/order" element={<Order user={this.state.user} />} />
            <Route
              path="/product"
              element={
                <Product
                  updateProductCart={this.bridgeProps}
                  cart={this.state.cart}
                  user={this.state.user}
                />
              }
            />
            <Route
              path="/invoice"
              element={<Invoice user={this.state.user} />}
            />
            <Route path="/validate" element={<Validate />} />
            {this.state.user && (
              <Route path="/" element={<Navigate replace to="/dash" />} />
            )}
            {!this.state.user && (
              <Route path="/" element={<Navigate replace to="/auth" />} />
            )}
          </Routes>
        </Container>
      </div>
    );
  }
}

export default App;
