import { useEffect, useRef, useState } from 'react'
import { DateRange } from 'react-date-range'

import format from 'date-fns/format'
import { addDays } from 'date-fns'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

import * as locales from "react-date-range/dist/locale";

const DateRangeComponent = (props) => {

  // date state
  const [range, setRange] = useState([
    {
      startDate: addDays(new Date(), -3), // new Date()
      //startDate: new Date(null), // new Date()
      endDate: new Date(), // addDays(new Date(), 7),
      maxDate: new Date(),
      //endDate: null,
      key: 'selection'
    }
  ])

  // open close
  const [open, setOpen] = useState(false)

  // get the target element to toggle 
  const refOne = useRef(null)

  useEffect(() => {
    // event listeners
    document.addEventListener("keydown", hideOnEscape, true)
    document.addEventListener("click", hideOnClickOutside, true)
  }, [])

  const onChange = (item) => {
    //console.log("on change",item);

    let start = item.selection.startDate ? item.selection.startDate.toISOString() : null;
    let end = item.selection.endDate ? item.selection.endDate.toISOString() : null;

    if(start != end){
      //setOpen(false);
    }
    // update parent component
    props.onDateChange(item)
    setRange([item.selection])

  }

  // hide dropdown on ESC press
  const hideOnEscape = (e) => {
    if( e.key === "Escape" ) {
      setOpen(false)
    }
  }

  // Hide on outside click
  const hideOnClickOutside = (e) => {
    if( refOne.current && !refOne.current.contains(e.target) ) {
      setOpen(false)
    }
  }

  const clearDate = (item) => {

    let fullRange = {
      selection : {      
        startDate: addDays(new Date(), -365*10),
        endDate: addDays(new Date(), 365),
        maxDate: addDays(new Date(), 365),
        key: 'selection'
      }
    }

    onChange(fullRange)
    setTimeout(() => { document.getElementById('date').value = '' }, 30)

  }

  const maxDate = new Date()

  return (
    <div className="calendarWrap">

      <input
        value={`${format(range[0].startDate, "dd/MM/yyyy")} au ${format(range[0].endDate, "dd/MM/yyyy")}`}
        readOnly
        className="inputBox"
        onClick={ () => setOpen(open => !open) }
        id="date"
        placeholder="Dates"
      />

      <div className="dateWrapper w-90" ref={refOne}>
        {open && 
          <DateRange
            onChange={onChange}
            editableDateInputs={true}
            moveRangeOnFirstSelection={false}
            retainEndDateOnFirstSelection={true}
            dateDisplayFormat="dd/MM/yyyy"
            ranges={range}
            months={1}
            direction="horizontal"
            className="calendarElement"
            locale={locales["fr"]}
            maxDate={maxDate}
          />
        }
        
      </div>
      <button className="btn btn-secondary" onClick={clearDate}><img src="../img/reinit.svg" width={25} alt="Reinitialiser" className="mr-2 d-inline-block" />Reinitialiser</button>
    </div>
  )
}

// DataRange : input = {true} ?

export default DateRangeComponent