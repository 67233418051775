import React, { Component } from "react"
import { Helmet } from 'react-helmet';
import Col from 'react-bootstrap/Col';
import InvoiceComponent from '../components/InvoiceList.js';


class Invoice extends Component {

    componentDidMount() {
        document.getElementById('main').classList.add('page-invoice')
    }

    componentWillUnmount() {
        document.getElementById('main').classList.remove('page-invoice')
    }

    render() {
        return (
            <>
                <Helmet>
                  <title>Mes factures | Restofuté</title>
                  <meta name="description" content="Liste de vos factures" />
                </Helmet>
                
                <Col id="invoices" xs="12" sm="12" lg="10">
                    <h1>Historique de vos factures</h1>
                    <p>Retrouvez l’intégralité de vos factures sur commandes fournisseurs</p>
                    <InvoiceComponent {...this.props} />
                </Col>
                    
            </>
        );
    }
}

export default Invoice;