import configData from "../../Config";
class Request {

    defaultOptions() {
        return {
            //Ajax request parameters
            'method': 'POST',
            'enctype': null,
            'cache': null,
            'contentType': null,
            'processData': null,
            'data': new FormData(),

            //GraphQL parameters
            'endpoint': configData.API_ENDPOINT,

            //callbacks
            'before': function(){},
            'success': null,
            'error': null,
            'after': function(){},
            'progress': function(e){},
        };
    }

    constructor(options) {
        this.settings = Object.assign(this.defaultOptions(), options);

        this.initialize();
    }

    initialize(){
        return this;
    }

    buildData(){
        return this.settings.data;
    }

    stringify(param){
        let self = this
        if(Array.isArray(param)){ // In case of an array, recursively call our function on each element.
            let results = [];
            for(let elem of param){
                results.push(self.stringify(elem));
            }
            return "[" + results.join(",") + "]";
        }
        else if(typeof param === "object"){ // In case of an object, loop over its keys and only add quotes around keys that aren't valid JavaScript variable names. Recursively call our function on each value.
            let props = Object
                .keys(param)
                .map(function(key){
                    // A valid JavaScript variable name starts with a dollar sign (?), underscore (_) or letter (a-zA-Z), followed by zero or more dollar signs, underscores or alphanumeric (a-zA-Z\d) characters.
                    if(key.match(/^[a-zA-Z_$][a-zA-Z\d_$]*$/) === null) // If the key isn't a valid JavaScript variable name, we need to add quotes.
                        return `"${key}":${self.stringify(param[key])}`;
                    else
                        return `${key}:${self.stringify(param[key])}`;
                })
                .join(",");
            return `{${props}}`;
        }
        else{ // For every other value, simply use the native JSON.stringify() function.
            return JSON.stringify(param);
        }
    }

    execute(){
        var self = this;
        this.before();

        let data = this.buildData();
        if(!data){
            this.error("No query builded, execution failed",{});
            console.log("request DATA BUILD FAILED");
            return this.after();
        }

        this.xhr = new XMLHttpRequest();
        this.xhr.withCredentials = false;
        this.xhr.open(this.settings.method,this.settings.endpoint,true);

        let apiToken = localStorage.getItem('access_token') ?? false;
        if(apiToken){
            this.xhr.setRequestHeader("Authorization","Bearer "+apiToken);
        }

        this.xhr.upload.onprogress = (e) => {
            self.settings.progress(e.loaded / e.total * 100);
        };

        this.xhr.onload = () => {

            if (this.xhr.status === 403) {
                self.error('HTTP Error: ' + this.xhr.status);
                return;
            }

            if (this.xhr.status < 200 || this.xhr.status >= 300) {
                self.error('HTTP Error: ' + this.xhr.status);
                return;
            }

           let json = JSON.parse(this.xhr.responseText)

            if (!json) {
                self.error('Invalid JSON: ' + this.xhr.responseText);
                return;
            }
            if(!json.data){
                self.error('GraphQL error',json);
            }else{
                self.success(json);
            }
        };

        this.xhr.onloadend  = () => {
            self.after();
        };

        this.xhr.send(data);
    }
    
    abort(){
        return this.xhr.abort;
    }

    before(){
        return this.settings.before();
    }

    error(message, data){
        if( this.settings.error){
            return this.settings.error(message,data);
        }
    }

    success(xhr){
        this.error("Success must be implemented",xhr);
    }

    after(){
        return this.settings.after();
    }

}

export default Request;


